import React, { createRef } from "react";
import PropTypes from "prop-types";

import "react-bootstrap-typeahead/css/Typeahead.css";
import { Link, withRouter } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import MyTable from "../../components/MyTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faPlus, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../components/IconButton";
import PartnerService from "../../services/PartnerService";
import Toast from "../../services/Toast";
import PickupPartner from "../../components/PickupPartner";
import MySpinner from "../../components/MySpinner";
import confirm from "../../components/Confirm";

class Partners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showPickup: false,
            skip: [],
        }
        this.tableRef = createRef();
        this.handleRemove = this.handleRemove.bind(this);
        this.reload = this.reload.bind(this);
    }

    componentDidMount() {
        this.reload();
    }

    reload() {
        this.setState({ loading: true });
        PartnerService.byBrand(this.props.brandId).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of partners");
                return;
            }
            this.tableRef.current?.populate(data);
            this.setState({ skip: data.map((partner) => partner.id) });
        });
    }

    async handleRemove(evt) {
        let obj = this.tableRef.current.getRowFromEvent(evt);

        if (
            await confirm("Are you sure?", "Yes", "No", {
                message:
                    "You are about to unlink Parner *" +
                    obj.row.name +
                    "* from the list. Do you want to proceed?",
            })
        ) {
            PartnerService.unlink(this.props.brandId, obj.row.module_id).then((result) => {
                if (result) {
                    Toast.ok("Partner unlinked successfully.");
                    this.tableRef.current.deleteRow(obj.row);
                    this.setState({ skip: this.state.skip.filter((id) => id !== obj.row.id) });
                } else {
                    Toast.error(
                        "Something went wrong while unlinking the partner."
                    );
                }
            });
        }
    }

    render() {

        return (
            <Container className="pt-4" >
                <PickupPartner
                    modules={[PartnerService.WHERE_TO_BUY_MODULE_ID]}
                    show={this.state.showPickup}
                    onClose={() => { this.setState({ showPickup: false }) }}
                    skip={this.state.skip}
                    callbackPromise={(partners) => new Promise((resolve, reject) => {
                        PartnerService.link(
                            this.props.brandId,
                            partners[0].value,
                            PartnerService.WHERE_TO_BUY_MODULE_ID
                        ).then((data) => {
                            if (data !== true) {
                                Toast.error("Something went wrong while linking the partner to the brand");
                                reject(false);
                                return;
                            }
                            this.reload();
                            resolve(true);
                        }).catch((err) => {
                            reject(err);
                        });
                    })}
                />
                <MyTable
                    name="partners"
                    dynamic={false}
                    ref={this.tableRef}
                    sortBy="name"
                    sortOrder="asc"
                    headers={
                        [
                            {
                                field: 'id',
                                label: "ID",
                                sortable: true,
                                searchable: true,
                                width: "60px",
                            },
                            {
                                field: 'name',
                                label: 'Partner',
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: 'email',
                                label: 'E-mail',
                                sortable: true,
                                searchable: true
                            },
                            {
                                field: "enabled",
                                label: "Status",
                                width: "130px",
                                options: [{ label: 'Enabled', value: '1' }, { label: 'Disabled', value: '0' }],
                                allowEmpty: true,
                                sortable: true,
                            },
                            // {
                            //     field: 'module_name',
                            //     label: 'Module',
                            //     sortable: true,
                            //     searchable: true
                            // },
                            {
                                field: 'control',
                                width: "50px",
                                content: <Button
                                    size="sm"
                                    variant="success"
                                    className="tdButton"
                                    onClick={() => { this.setState({ showPickup: true }) }}
                                >
                                    <FontAwesomeIcon icon={faPlus} />
                                </Button>
                            },
                        ]
                    }
                    renderColumns={{
                        name: (row) => <Link className="link" to={`/partner/${row.id}`}>{row.name}</Link>,
                        enabled: {
                            format: (row) => row.enabled
                                ? (
                                    <FontAwesomeIcon
                                        style={{
                                            color: "green",
                                        }}
                                        icon={faCheck}
                                    />
                                )
                                : (
                                    <FontAwesomeIcon
                                        style={{ color: "red" }}
                                        icon={faTimes}
                                    />
                                ),
                            cvs: (row) => row.enabled ? "Enabled" : "Disabled",
                            className: "text-center"
                        },
                        control: {
                            format: (row) => {
                                if (typeof row.deleting !== "undefined") {
                                    return <MySpinner />
                                }

                                return <IconButton
                                    size="sm"
                                    variant="danger"
                                    className="tdButton"
                                    hint="Unlink Partner"
                                    icon={faTrash}
                                    onClick={this.handleRemove}
                                />
                            },
                            className: "text-center"
                        }
                    }
                    }
                />
            </Container>
        );
    }
}


Partners.propTypes = {
    /** Brand ID */
    brandId: PropTypes.number.isRequired,
};

export default withRouter(Partners);
