import axios from "axios";

import Config from "./Config";
import APIHelper from "./APIHelper";

const PartnerService = {
    WHERE_TO_BUY_MODULE_ID: 13,
    WHERE_TO_BUY_MODULE_NAME: "where_to_buy_module",
    all(modules = []) {
        return axios
            .get(Config.API_URL + "partners", {
                params: { modules }
            })
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    modules() {
        return axios
            .get(Config.API_URL + "partners/modules")
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },

    create(params) {
        return axios
            .post(Config.API_URL + "partners", { ...params })
            .then((response) => response.data)
            .catch(APIHelper.returnFalse);
    },

    get(id) {
        return axios
            .get(Config.API_URL + `partners/${id}`)
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    update(id, params) {
        return axios
            .put(Config.API_URL + `partners/${id}`, { ...params })
            .then((response) => response.data)
            .catch(APIHelper.returnFalse);
    },
    byBrand(brand_id) {
        return axios
            .get(Config.API_URL + `brand/${brand_id}/partners`)
            .then(APIHelper.returnDataOrEmptyArray)
            .catch(APIHelper.returnEmptyArray);
    },
    link(brand_id, partner_id, module_id) {
        return axios
            .post(Config.API_URL + `brand/${brand_id}/link-partner`, {
                partner: partner_id,
                module: module_id,
            })
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnEmptyArray);
    },
    unlink(brand_id, module_id) {
        return axios
            .deletee(Config.API_URL + `brand/${brand_id}/unlink-partner/${module_id}`)
            .then(APIHelper.checkStatus)
            .catch(APIHelper.returnEmptyArray);
    }
};

export default PartnerService;
