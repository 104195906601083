import React from "react";
import PropTypes from "prop-types";

import { Button, Container, Modal, Row } from "react-bootstrap";
import ProcessingButton from "./ProcessingButton";
import MultiSelect from "react-multi-select-component";
import PartnerService from "../services/PartnerService";
import Toast from "../services/Toast";

class PickupPartner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: [],
            importing: false,
            loading: true,
            selected: []
        }
        this.onEntering = this.onEntering.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onEntering() {
        this.setState({
            importing: false,
            loading: false,
            selected: [],
            filteredOptions: this.state.options.filter((option) => this.props.skip.indexOf(option.value) === -1)
        });
    }

    componentDidMount() {
        PartnerService.all(this.props.modules).then((data) => {
            if (data === false) {
                Toast.error("Something went wrong while loading the list of partners");
                return;
            }
            const options = data.map((partner) => { 
                return { label: partner.name, value: partner.id } 
            });
            this.setState({
                    options,
                    filteredOptions: options.filter((option) => this.props.skip.indexOf(option.value) === -1),
                    loading: false });
        });
    }

    onChange(selected) {
        if (selected.length > 1) {
            selected.splice(0, 1);
        }
        this.setState({ selected });
    }

    render() {
        const cancel = () => {
            if (this.state.importing) {
                return;
            }
            this.props.onClose();
        }

        const save = () => {
            if (this.state.importing) {
                return;
            }
            this.setState({ importing: true }, () => {
                this.props.callbackPromise(this.state.selected).then((result) => {
                    if (result === true) {
                        this.setState({ importing: false, selected: [] });
                        this.props.onClose(result);
                    }
                }).catch(() => {
                    this.setState({ importing: false });
                });
            });
        }

        return (
            <Modal
                size="lg"
                show={this.props.show}
                onHide={cancel}
                onEntering={this.onEntering}
                backdrop={!this.state.importing}
                keyboard={!this.state.importing}
                centered
            >
                <Modal.Header closeButton onHide={cancel}>
                    <Modal.Title>
                        {this.props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="text-center">
                    <Container>
                        <Row>
                            <MultiSelect
                                hasSelectAll={false}
                                options={this.state.filteredOptions}
                                onChange={this.onChange}
                                value={this.state.selected}
                                labelledBy="Select"
                            />
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer style={{
                    justifyContent: "center"
                }}>
                    <Button disabled={this.state.importing} size="sm" variant="secondary" onClick={cancel}>Cancel</Button>
                    <ProcessingButton
                        size="sm"
                        processing={this.state.importing}
                        processingLabel={this.props.processingLabel}
                        label={this.props.processLabel}
                        onClick={save}
                        disabled={this.state.selected.length === 0}
                    />
                </Modal.Footer>
            </Modal >
        );
    }
}

PickupPartner.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    onUpdated: PropTypes.func,
    issues: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    processLabel: PropTypes.string.isRequired,
    processingLabel: PropTypes.string.isRequired,
    callbackPromise: PropTypes.func,
    skip: PropTypes.arrayOf(PropTypes.number),
    modules: PropTypes.array,
};

PickupPartner.defaultProps = {
    show: false,
    onClose: () => { console.log("onClose") },
    issues: [],
    title: 'Link Partner',
    processLabel: 'Link',
    processingLabel: 'Linking ...',
    skip: [],
    callbackPromise: (partners) => { return new Promise((resolve) => { console.log(partners); setTimeout(() => { resolve(true) }, 2000); }); },
    modules: [],
};

export default PickupPartner;
